import React from 'react'
import AddUserForm from '../../components/forms/user/AddUserForm'

const AddUser = () => {
  return (
    <div className='add__user'>
        <AddUserForm/>
    </div>
  )
}

export default AddUser
import React from 'react'

const Admin = () => {
  return (
    <div className="admin page">
    <div className="admin__inner container">

    </div>

  </div>
  )
}

export default Admin
import React from 'react'

const PaymentSuccess = () => {
  return (
    <div>
        <h3>SUCCESSSS</h3>
    </div>
  )
}

export default PaymentSuccess